<template>
	<div>
		<app-section-with-image
			:banner='true'
			:title='$t("main_content.about.banner.title")'
			:contentArray='$t("main_content.about.banner.description")'
			firstImgSrc='about-page/about-banner-1.jpg'
			secondImgSrc='about-page/about-banner-2.jpg'
			imagePosition='bottom right'
			rowAlignItems='center'
			:reverse='true'
			colorBg='banner'
		/>

		<app-section-with-image
			:title='$t("main_content.about.section_1.title")'
			:contentArray='$t("main_content.about.section_1.info")'
			firstImgSrc='about-page/about-section-1-1.jpg'
			secondImgSrc='about-page/about-section-1-2.jpg'
			imagePosition='top right'
			rowAlignItems='flex-end'
		/>

		<app-section-with-image
			:title='$t("main_content.about.section_2.title")'
			:contentArray='$t("main_content.about.section_2.info")'
			firstImgSrc='about-page/about-section-2-1.jpg'
			secondImgSrc='about-page/about-section-2-2.jpg'
			imagePosition='bottom right'
			:reverse='true'
			colorBg='greenbg'
		/>

		<about-values />
	</div>
</template>

<script>
	import AppSectionWithImage from '@/components/main/AppSectionWithImage'
	import AboutValues from '@/views/main/components/AboutValues'

	export default {
		components: {
			AppSectionWithImage,
			AboutValues
		}
	}
</script>
