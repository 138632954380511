<template>
	<div class="app-section" :class='classes'>
		<div class="app-container">
			<div class="row" :style='rowAlignItems ? `align-items: ${rowAlignItems}` : ""'>
				<div class="col-6">
					<AppDoubleImage
						:class='imagePosition'
						:firstImgSrc='firstImgSrc'
						:secondImgSrc='secondImgSrc'
					/>
				</div>
				<div class="col-6">
					<div class="app-section__content">
						<h2 :class='banner ? "heading-1 white--text" : "heading-2 green--text"'>{{ title }}</h2>
						<div class="app-section__desc">
							<p :key='`section_1_content_${+i}`' v-for='(elem, i) in contentArray' >{{ elem }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AppDoubleImage from '@/components/main/AppDoubleImage';

	export default {
		props: ['title', 'contentArray', 'firstImgSrc', 'secondImgSrc', 'imagePosition', 'reverse', 'colorBg', 'rowAlignItems', 'banner'],
		components: {
			AppDoubleImage
		},
		computed: {
			classes() {
				return {
					"app-section--reverse": this.reverse,
					[this.colorBg ? "app-section--" + this.colorBg : false]: this.colorBg,
					"app-section--banner": this.banner
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.app-section {
		overflow: hidden;
		@include minw( $grid-breakpoints-sm ) {
			padding: 120px * .6 0 140px * .6;
		}
		@include minw( $grid-breakpoints-md ) {
			padding: 120px * .8 0 140px * .8;
		}
		@include minw( $grid-breakpoints-lg ) {
			padding: 120px 0 140px;
		}
		@include maxw( $grid-breakpoints-sm - 1 ) {
			&:not(.app-section--banner) {
				.col-6:first-child {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
		.heading-1 {
			margin-bottom: 20px;
			@include minw( $grid-breakpoints-sm ) {
				margin-bottom: 50px;
			}
		}
		.heading-2 {
			margin-bottom: 20px;
			@include minw( $grid-breakpoints-sm ) {
				margin-bottom: 60px;
			}
		}
		&__content {
			padding: 30px 0 45px;
			@include minw( $grid-breakpoints-sm ) {
				padding: 0;
			}
		}
		&__desc {
			max-width: 580px;
			p {
				line-height: 1.6;
				margin-bottom: 25px;
			}
		}
		&--reverse {
			@include minw( $grid-breakpoints-sm ) {
				.row {
					flex-direction: row-reverse;
				}
				.double-img {
					margin-left: auto;
				}
			}
		}
		&--banner {
			background-color: $green;
			padding-top: 100px;
			@include minw( $grid-breakpoints-sm ) {
				padding: 184px * .6 0 150px * .6;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 184px * .8 0 150px * .8;
			}
			@include minw( $grid-breakpoints-lg ) {
				padding: 184px 0 150px;
			}
			&:before {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: rgba(#C4C4C4, .3);
				mask-image: url('../../assets/images/background-lines.svg');
			}
			.app-section__desc {
				color: $white;
				max-width: 420px;
			}
			@include minw( $grid-breakpoints-md ) {
				.col-6 {
					&:first-child {
						flex: 0 0 auto;
						width: auto;
					}
					&:last-child {
						flex-basis: 0;
						flex-grow: 1;
						max-width: 100%;
					}
				}
			}
			@include maxw( $grid-breakpoints-sm - 1 ) {
				.double-img {
					max-width: 540px;
					margin: auto;
					&.bottom.right {
						margin-bottom: 30px;
						.double-img__item--second {
							position: absolute;
							display: block;
							bottom: -30px;
							right: -38px;
						}
					}
				}
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				.double-img {
					&__item {
						height: 60vw;
					}
					&.bottom.right {
						margin-right: 38px;
					}
				}
			}
		}
		&--greenbg {
			background-color: rgba($green, .1);
			&:before {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 38%;
				background: url('../../assets/images/background-lines-about.svg') no-repeat 0% 100%;
				background-size: cover;
			}
		}
	}
</style>
