<template>
	<div class="about-values">
		<div class="app-container">
			<div class="about-values__bglogo">
				<span
					class="app-icon"
					:style="`width: 650px; height: 650px;`"
					v-html="require(`!html-loader!@/assets/logo.svg`)"
				/>
			</div>
			<h2 class="heading-2 green--text">{{ $t('main_content.about.values_title') }}</h2>
			<div class="row">
				<div class='col-6'
					v-for="(value, i) in $t('main_content.about.values')"
					:key='"value_key_"+i'
				>
					<div
						class="about-values__item"
						:style="`background-color: ${items_background[i]}`"
					>
						<div class="about-values__content">
							<h3 class="about-values__title heading-3 white--text">{{ value.title }}</h3>
							<div class="about-values__inner">
								<span class="about-values__desc">{{ value.short_desc }}</span>
								<p class="about-values__desc">{{value.long_desc}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			items_background: ['#11698E', '#1F8F8F', '#16C79A', '#19456B']
		})
	}
</script>

<style lang='scss'>
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.about {
		&-values {
			padding: 65px 0 40px;
			position: relative;
			overflow: hidden;
			@include minw( $grid-breakpoints-sm ) {
				padding: 150px * .6 0 90px * .6;
				.row {
					margin-right: -32px;
					margin-left: -32px;
				}
				.col-6 {
					padding-left: 32px;
					padding-right: 32px;
				}
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 150px * .8 0 90px * .8;
			}
			@include minw( $grid-breakpoints-lg ) {
				padding: 150px 0 90px;
			}
			&__bglogo {
				display: none;
				@include minw( $grid-breakpoints-md ) {
					overflow: hidden;
					display: block;
					position: absolute;
					right: 86%;
					top: -30px;
					.app-icon {
						max-width: none !important;
					}
					svg {
						fill: rgba($green-persian, .06);
						.logo-text {
							display: none !important;
						}
					}
				}
			}
			.heading-2 {
				margin-bottom: 30px;
				@include minw( $grid-breakpoints-sm ) {
					margin-bottom: 32px;
				}
			}
			&__item {
				position: relative;
				width: 100%;
				margin-bottom: 20px;
				@include minw( $grid-breakpoints-sm ) {
					margin-bottom: 64px * .6;
					height: 450px * .6;
					&:hover {
						span {
							position: absolute;
							opacity: 0;
							transform: translateY(-50px);
						}
						p {
							display: block;
						}
						&::after {
							transform: translate(30px, 30px);
						}
					}
				}
				@include minw( $grid-breakpoints-md ) {
					margin-bottom: 64px * .8;
					height: 450px * .8;
				}
				@include minw( $grid-breakpoints-xl ) {
					margin-bottom: 64px;
					max-width: 655px;
					height: 450px;
				}
				&::after {
					content: '';
					position: absolute;
					display: block;
					z-index: -1;
					width: 100%;
					height: 100%;
					background-color: inherit;
					opacity: .2;
					transition: $transition;
					top: 0;
					left: 0;
				}
			}
			&__content {
				padding: 25px 35px;
				overflow-x: hidden;
				height: 100%;
				overflow-y: auto;
				@include minw( $grid-breakpoints-sm ) {
					padding: 45px * .6;
				}
				@include minw( $grid-breakpoints-md ) {
					padding: 45px * .8;
				}
				@include minw( $grid-breakpoints-lg ) {
					padding: 45px;
				}
			}
			&__inner {
				position: relative;
			}
			.heading-3 {
				margin-bottom: 20px;
			}
			&__desc {
				color: $white;
				padding-bottom: 20px;
				display: block;
				@include minw( $grid-breakpoints-md ) {
					transition: $transition;
				}
			}
			span {
				font-size: 16px;
				font-weight: 700;
				line-height: 1.4;
				max-width: 524px;
				@include minw( $grid-breakpoints-sm ) {
					font-size: 25px * .8;
				}
				@include minw( $grid-breakpoints-md ) {
					font-size: 25px * .9;
				}
				@include minw( $grid-breakpoints-lg ) {
					font-size: 25px;
				}
			}
			p {
				font-size: 14px;
				line-height: 1.6;
				max-width: 564px;
				@include minw( $grid-breakpoints-sm ) {
					font-size: 18px * .8;
					display: none;
				}
				@include minw( $grid-breakpoints-md ) {
					font-size: 18px * .9;
				}
				@include minw( $grid-breakpoints-lg ) {
					font-size: 18px;
				}
			}
		}
	}
</style>
